<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">互动问答</div>
      <div class="bt" @click="toback">返回</div>
    </div>
    <div class="line"></div>

    <div class="maincontain">
      <div class="returnbt">
        <div class="lefttitle">{{ title_name }}</div>
        <div class="bt" @click="release">发布讨论</div>
      </div>
      <div class="bts">
        <div class="check-group">
          <el-radio-group v-model="queryParams.type">
            <el-radio :label="0">全部</el-radio>
            <el-radio :label="1">仅老师参与</el-radio>
            <el-radio :label="2">回复最多</el-radio>
          </el-radio-group>
          <el-select class="btleft" v-model="queryParams.belong_type" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input class="elinput" v-model="queryParams.keyword" placeholder="请输入内容" clearable>

          </el-input>
          <div class="bt restbt" @click="searchbt">查询</div>
          <div class="bt" @click="resetbt">重置</div>
        </div>
      </div>
      <div class="itemcontain">
        <div class="item" v-for="(item, index) in qadata" :key="index">
          <div class="title">{{ item.title }}</div>
          <div class="item-two">
            <div v-if="item.is_top === 1" class="item-two-bt item-two-bt-active">
              置顶
            </div>
            <div v-if="item.is_teacher_join === 1" class="item-two-bt">
              老师参与
            </div>
          </div>
          <div class="text">
            {{ item.content }}
          </div>
          <div class="bottom-contain">
            <div class="bottom-left">
              <el-image class="elimage" :src="item.avatar" />
              <div class="teach">{{ item.author_name }}</div>
              <div class="time">{{ item.created_at }}</div>
              <div class="time">发布</div>
            </div>
            <div class="bottom-right">
              <div class="bottom-right-item">
                <el-image class="elimage1" :src="icon1" />
                <div class="num">{{ item.view_count }}</div>
              </div>
              <div class="line"></div>
              <div class="bottom-right-item" @click="setlikebt(item.id)">
                <template v-if="item.like_status == 0">
                  <el-image class="elimage2" :src="icon2" />
                  <div class="num">{{ item.like_count }}</div>
                </template>
                <template v-else-if="item.like_status == 1">
                  <el-image class="elimage2" :src="icon5" />
                  <div class="num">{{ item.like_count }}</div>
                </template>
              </div>
              <div class="line"></div>
              <div class="bottom-right-item" @click="toreply(item)">
                <el-image class="elimage3" :src="icon3" />
                <div class="num">{{ item.reply_count }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="elpagination">
        <div class="elpagination-title">共{{ total }}条</div>
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="10"
          :current-page="queryParams.page" @current-change="pageChange">
        </el-pagination>
      </div>

    </div>
    <Customdialog ref="customdialog" title="发表评论">
      <div slot="dialogbody" class="dialogbody">
        <div class="title">
          <span>标题：</span>
          <el-input class="elinput" v-model="title" placeholder="请输入标题" clearable></el-input>
        </div>
        <div>
          <div class="interactiveqa_content">
            <span>内容：</span>
          </div>
          <div class="interactiveqa_edit">
            <Tinymce ref="editor" v-model="content" style="height: auto; border-radius: 22px;" @setInput="getValue">
            </Tinymce>
          </div>
        </div>
      </div>
      <div slot="dialogfooter" class="dialogfooter">
        <div class="bt" @click="canclebt">取消</div>
        <div class="bt btactive" @click="submitbt">确定</div>
      </div>
    </Customdialog>
  </div>
</template>

<script>
import { getQaList, setQaLike, getQaAdd } from "@/api/qa";
import dayjs from "dayjs";
import Tinymce from "@/components/VueTinymce/index.vue";
import Customdialog from "@/components/customdialog.vue";
export default {
  name: "mainpage",
  components: { Customdialog, Tinymce },
  data() {
    return {
      queryParams: {
        keyword: "",
        belong_type: 0,
        type: 0,
        related_type: 2,
        related_id: '',
        page: 1,
        per_page: 10
      },
      total: 0,
     
     
      title: "",
      content: "",
    
      testimage: require("@/assets/test.png"),
      icon1: require("@/assets/coursedetails/查看.png"),
      icon2: require("@/assets/coursedetails/点赞.png"),
      icon3: require("@/assets/coursedetails/回复.png"),
      icon5: require("@/assets/coursedetails/dzxz.png"),
      //单选改为道航点击切换选择
      radioList: [
        { id: 1, name: "全部", radio: "0" },
        { id: 2, name: "仅老师参与", radio: "1" },
        { id: 3, name: "回复最多", radio: "2" },
      ],
      
      options: [
        {
          value: 0,
          label: "全部讨论",
        },
        {
          value: 1,
          label: "我发表的问答",
        },
      ],
      qadata: [],
      iteminfo: "",
      flag: false,
    };
  },
  computed: {
    qa_id() {
      return this.$route.query.qa_id
    },
    title_name() {
      return this.$route.query.name
    }
  },
  created() {
    this.queryParams.related_id = this.qa_id
    this.getqalist();
  },
  methods: {

    toback() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 0,
          componentId: "Virtualsimulationexperiment",
        },
      });
    },
    release() {
      this.content = ''
      this.title = ''
      this.$refs.customdialog.dialogopenbt();
    },
    toreply(item) {
      this.$router.push({
        path: "/home/personalcenter/interactiveqadetails",
        query: {
          id: item.id,
        },
      });
    },
    releasebt() {
      this.$refs.customdialog.dialogopenbt();
    },
    canclebt() {
      this.$refs.customdialog.dialogclosebt();
    },
  
    searchbt() {
      this.queryParams.page = 1
      this.getqalist();
    },
    resetbt() {
      this.queryParams.keyword = "";
      this.queryParams.belong_type = 0;
      this.queryParams.related_type = 0;
       this.queryParams.page = 1
       this.queryParams.per_page = 10
      this.getqalist();
    },
    pageChange(val){
      this.queryParams.page = val
       this.getqalist();
    },
    setlikebt(id) {
      this.setQaLike({ id: id });
    },

    submitbt() {
      this.QaAdd({
        title: this.title,
        related_type: 2,
        related_id: this.qa_id,
        content: this.content,
      });
    },
    QaAdd(params) {
      getQaAdd(params)
        .then((response) => {
          if (response.code == 0) {
            this.$refs.customdialog.dialogclosebt();
            this.getqalist();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getqalist() {
      getQaList(this.queryParams)
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            this.qadata = response.data.data
            this.total = response.data.total
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setQaLike(params) {
      setQaLike(params)
        .then((response) => {
          this.getqalist({
            keyword: "",
            belong_type: 0,
            type: 0,
            related_type: 2,
            related_id: this.qa_id,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取富文本得值
    getValue(data) {
      this.content = data;
    }
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  background-color: #fff;
  min-height: calc(100vh - 240px);
  padding-bottom: 20px;
  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    padding-top: 10px;
    height: 40px;
    .head-title {
      font-size: 20px;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
    }
    .elinput {
      width: 300px;
      ::v-deep .el-input__suffix {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .bt {
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
      margin-right: 20px;
      font-size: 14px;
    }
  }
  .line {
    margin-top: 14px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }

  .maincontain {
    background: #ffffff;
    border-radius: 4px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    .returnbt {
      display: flex;
      justify-content: space-between;
      align-items: center;

      height: 83px;
      padding: 0 20px;
      background: #f9f9f9;
      border-radius: 5px;
      .lefttitle {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #222222;
      }
      .bt {
        width: 88px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        background: #3d84ff;
        border-radius: 4px;
        color: white;
        user-select: none;
        cursor: pointer;
        font-size: 14px;
      }
    }
    .bts {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btleft {
        margin-left: 20px;
      }

      .check-group {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .check-all {
          margin-right: 20px;
        }
        .elinput {
          margin-left: 20px;
          width: 300px;
          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .bt {
          margin-left: 15px;
          width: 64px;
          height: 38px;
          line-height: 38px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #3d84ff;
          border-radius: 2px;
          user-select: none;
          cursor: pointer;
          font-size: 14px;
        }
        .restbt {
          background: #3d84ff;
          color: white;
        }
      }
    }
    .itemcontain {
      margin-top: 20px;
      background: #ffffff;
      .item {
        padding-top: 20px;
        padding-left: 30px;
        margin-bottom: 10px;
        height: 180px;
        // background: #f7f7f7;
        border-bottom: 1px solid #cccccc;
        .title {
          font-size: 20px;
          font-weight: 400;
          color: #222222;
        }
        .item-two {
          margin-top: 6px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .item-two-bt {
            margin-right: 10px;
            padding: 2px;
            background: #ffffff;
            border: 1px solid #cccccc;
            border-radius: 4px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #222222;
          }
          .item-two-bt-active {
            border: 1px solid #ca261d;
          }
        }
        .text {
          margin-top: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
        }
        .bottom-contain {
          margin-top: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .bottom-left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .elimage {
              width: 30px;
              height: 30px;
              border-radius: 50%;
            }
            .teach {
              margin-left: 10px;
              margin-right: 10px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #222222;
            }
            .time {
              margin-right: 10px;
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #a0a0a0;
            }
          }
          .bottom-right {
            padding-right: 100px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .bottom-right-item {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              cursor: pointer;
              .elimage1 {
                width: 22px;
                height: 16px;
              }
              .elimage2 {
                width: 19px;
                height: 21px;
                margin-bottom: 4px;
              }
              .elimage3 {
                width: 19px;
                height: 16px;
              }
              .num {
                margin-left: 6px;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #a0a0a0;
              }
            }
            .line {
              margin-left: 20px;
              margin-right: 20px;
              height: 15px;
              margin-top: 5px;
              border-right: 2px solid #9b9b9b;
            }
          }
        }
      }
    }
  }
  .releasebt {
    margin-top: 80px;
    margin-right: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .bt {
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      user-select: none;
      cursor: pointer;
      color: white;
    }
  }

  .dialogbody {
    padding: 20px;
    overflow: hidden;
    .elinput {
      margin-bottom: 10px;
      width: 40%;
      padding-left: 18px;
    }
    .interactiveqa_content{
      width: 60px;
      float: left;
    }
    .interactiveqa_edit{
      width: calc( 100% - 100px);
      float: left;
    }
  }
  .dialogfooter {
    padding-right: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .bt {
      margin-right: 20px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 4px;
      font-size: 18px;
      font-weight: 400;
      color: #a0a0a0;
      user-select: none;
      cursor: pointer;
      font-size: 14px;
    }
    .btactive {
      background: #3d84ff;
      color: white;
      border: 1px solid #3d84ff;
    }
  }
}
.elpagination {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    padding-right: 20px;
    align-items: center;
    .elpagination-title {
      margin-right: 4px;
    }
  }
</style>
